/* eslint-disable */
import React, {useState} from "react"
import {Button} from "../../../../components/button/Button"
import {FlipIcon, Icon} from "../../../../components/text/icon/icon"
import {Text} from "../../../../components/text/Text"
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form"
import {FamilyCard} from "../../../litle/Provision/content/FamilyCard"
import FormInput from "../../../../components/input/FormInput"
import Location, {GeolocApiTye} from "../../../../components/input/location/Location"
import CheckBox from "../../../../components/input/checkbox/CheckBox"
import {ReservationInformationType, UtilityStepEnum, VehicleApiType} from "../UtilityCustom"
import {useTranslation} from "react-i18next"
import SlotPicker, {SlotType} from "../../../../components/input/slotPicker/SlotPicker"
import Modal from "../../../../components/Modal/Modal"
import {format} from "date-fns"
import {fr} from "date-fns/locale"

const goToMain = () => {
	if(window.self !== window.top) {
		window.parent.postMessage({
			goToMain: true
		}, process.env.REACT_APP_TRUST_DOMAIN as string)
	} else {
		window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN as string
	}
}

type AddressStepProps = {
	token: string | null
	loader: {
		active: boolean
		setActive: React.Dispatch<React.SetStateAction<boolean>>
	}
	vehicles: VehicleApiType | undefined
	reservation: {
		data: ReservationInformationType,
		set: React.Dispatch<React.SetStateAction<ReservationInformationType>>
	}
	setFormStep: React.Dispatch<React.SetStateAction<number>>
}

type Inputs = {
	typology: number
	zone: number
	loadingAddress: GeolocApiTye
	steps: {
		addr: GeolocApiTye
		isLoading: boolean
	}[]
	unloadingAddress: GeolocApiTye
	instant: boolean
	slot: {
		id: number
		range: string
	}
	option: boolean
}

type SlotResponseType = {
	success: boolean
	zone: number
	instantActive: boolean
	typology: number
	locationClose?: boolean
	picture: string
	smallPicture: string
	slots: SlotType
}

const AddressStep = ({ loader, token, vehicles, reservation, setFormStep }: AddressStepProps) => {
	const { t } = useTranslation("utilityCustom")

	const [ defaultData, setDefaultData] = useState<Inputs>()
	const [ openSlotModal, setOpenSlotModal ] = useState<boolean>(false)
	const [ locationClose, setLocationClose ] = useState(false)
	const [ apiError, setApiError ] = useState(false)

	const onSubmitAddress: SubmitHandler<Inputs> = (data) => {
		if(loader.active) {
			return
		}
		const fetchData = async() => {
			loader.setActive(true)
			setApiError(false)
			try {
				const formData = new FormData()
				setDefaultData(data)
				formData.append("data", JSON.stringify(data))
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/search/utility/ask-price`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`
					},
					body: formData
				})
				if(response.ok) {
					const priceResponse: ReservationInformationType = await response.json()
					reservation.set((prevState) => ({
						...prevState,
						price: priceResponse.price,
						searchHistory: priceResponse.searchHistory,
						slot: {
							id: data.slot.id,
							resume: data.slot.range
						},
						option: data.option,
						address: {
							...prevState.address,
							first: data.loadingAddress.address || "",
							last: data.unloadingAddress.address || "",
							steps: data.steps.map(step => ({
								addr: step.addr.address,
								isLoading: step.isLoading
							}))
						},
						contacts: {
							first: { name: data.loadingAddress.contact?.name ?? "", phone: data.loadingAddress.contact?.phone ?? "" },
							...data.steps.reduce((acc, step, index) => ({
								...acc,
								[index === 0 ? 'stepOne' : 'stepTwo']: { name: step.addr.contact?.name ?? "", phone: step.addr.contact?.phone ?? "" }
							}), {}),
							last: { name: data.unloadingAddress.contact?.name ?? "", phone: data.unloadingAddress.contact?.phone ?? "" },
						},
					}))
					setFormStep(UtilityStepEnum.BOOKING)
				} else {
					setApiError(true)
				}
			} catch (error) {
				console.log("Erreur lors de la récupération des données de l'API", error)
				setApiError(true)
				// todo gestion de l'erreur
			}
			loader.setActive(false)
		}
		fetchData()
	}

	const {
		watch,
		handleSubmit,
		control,
		setValue,
		getValues
	} = useForm<Inputs>({
		defaultValues: defaultData
	})
	const loadingAddress = watch("loadingAddress")
	const typology = watch("typology")
	const selectedSlot = watch("slot.range")
	const maxStepField = 2
	const { fields: stepFields, append: appendStep, remove: removeStep } = useFieldArray({
		control,
		name: "steps"
	})

	const [slotsApi, setSlotsApi] = useState<SlotResponseType>()

	const loadSlots = () => {
		if(!getValues("typology") || !getValues("loadingAddress")) {
			return
		}

		const fetchData = async() => {
			if(loader.active) {
				return
			}
			loader.setActive(true)
			setApiError(false)
			try {
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/search/utility/custom/request/vehicle`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`
					},
					body: JSON.stringify({
						typology: getValues("typology"),
						loadingAddress: getValues("loadingAddress")
					})
				})
				if(response.ok) {
					const slotsResponse: SlotResponseType = await response.json()
					if(slotsResponse.success && !slotsResponse.locationClose) {
						setValue("zone", slotsResponse.zone)
						setValue("slot", { id: 0, range: "" })
						reservation.set(prevState => ({
							...prevState,
							vehicle: {
								...prevState.vehicle,
								picture: slotsResponse.picture,
								smallPicture: slotsResponse.smallPicture
							}
						}))
						setLocationClose(false)
						setSlotsApi(slotsResponse as SlotResponseType)
					} else {
						setLocationClose(true)
					}
				} else {
					setLocationClose(true)
					setApiError(true)
				}
			} catch (error) {
				setApiError(true)
				console.log("Erreur lors de la récupération des données de l'API", error)
			}
			loader.setActive(false)
		}
		fetchData()
	}

	const changeSlotRange = async(date: Date) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/search/utility/slot/range?date=${date.toISOString()}&typology=${typology}&zone=${slotsApi?.zone}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			if(response.ok) {
				const data: { success: boolean, slots: SlotType } = await response.json()
				if(data.success) {
					setValue("slot", { id: 0, range: "" })
					setSlotsApi((prevState): SlotResponseType => ({
						...prevState,
						slots: data.slots
					}) as SlotResponseType)
					return
				}
			}
			setApiError(true)
			setOpenSlotModal(false)
		} catch (error) {
			setApiError(true)
			setOpenSlotModal(false)
			// todo Gestion d'erreur fatal
		}
	}

	return <div className="p-5">
		<div className="flex justify-start">
			<div>
				<Button type="none" text={t("form.address.back")} icon={{ side: "left", flip: FlipIcon.horizontal, type: "arrow-left" }}
					onClick={() => goToMain()}/>
			</div>
		</div>
		<div className="flex justify-center mt-2">
			<Text size="large">{t("title")}</Text>
		</div>
		<form onSubmit={handleSubmit(onSubmitAddress)}>
			<div className="p-2 text-c-neutral">
				<Text size="big">{t("form.address.utility.title")}</Text>
			</div>
			<Controller
				name="typology"
				control={control}
				defaultValue={undefined}
				rules={{ required: true }}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<div className="pb-5">
						<div className={`${error ? "warningForm" : ""} flex flex-wrap w-fit items-baseline justify-center lg:justify-start ${error ? "border-c-warning" : ""}`}>
							{vehicles && vehicles.map((item, key) => (
								<div key={key} className="p-2">
									<Controller
										name="option"
										control={control}
										defaultValue={defaultData?.option || false}
										rules={{ required: false }}
										render={({ field: { onChange: changeOption, value: valueOption } }) => (<>
											<FamilyCard
												icon={item.icon}
												label={item.label}
												description={item.description}
												selected={value === item.id}
												setSelected={async () => {
													changeOption(item.rules?.option ? valueOption : false)
													reservation.set((prevState) => ({
														...prevState,
														vehicle: {
															picture: item.icon,
															smallPicture: item.icon,
															title: item.label,
															option: item.rules?.option,
															characteristic: item.rules?.characteristic
														}
													}));
													onChange(item.id);
													await loadSlots();
												}}
											/>
											{value === item.id && item.rules && item.rules.option &&
												<div className="flex items-center">
													<CheckBox
														checked={valueOption}
														setChecked={changeOption}
													/>
													{item.rules.option} ?
												</div>
											}
										</>)}
									/>
								</div>
							))}
						</div>
						{error && <Text className="text-c-warning pl-5 -m-2 mt-2">{t("form.address.utility.error")}</Text>}
					</div>
				)}
			/>
			<div className="flex flex-row w-full">
				<div className="flex flex-col w-2/6">
					<div className="flex w-full pb-5">
						<Controller
							name="loadingAddress"
							control={control}
							defaultValue={undefined}
							rules={{required: true, validate: (value) => !!value?.address}}
							render={({field: {onChange, value}, fieldState: {error}}) => <FormInput className="w-full"
								inputText={t("form.address.loadingAddress.title")}
								titleSize="big"
								errorText={t("form.address.loadingAddress.error")}
								error={!!error}
							>
								<Text size="small" className="text-c-neutral">{t("form.address.loadingAddress.kind")}</Text>
								<div className={`flex w-full bg-white radius-common ${error ? "warningForm" : ""}`}>
									<Location
										type="primary"
										input={{
											value: value && value.address ? value.address : "",
											className: "m-2"
										}}
										placeholder={t("form.address.loadingAddress.placeholder")}
										setAddress={(val) => {
											onChange(val)
											loadSlots()
										}}
									/>
								</div>
							</FormInput>}
						/>
					</div>
					{stepFields.map((_field, index) => <div key={index} className="flex w-full">
						<Controller
							name={`steps.${index}.addr`}
							control={control}
							defaultValue={undefined}
							rules={{required: true}}
							render={({field: {onChange: addrOnChage, value: addrValue}, fieldState: {error}}) => <FormInput trashOnClick={removeStep}
								className="pb-5 w-full"
								inputText={t("form.address.step.title", {step: index + 1})}
								titleSize="big"
								errorText={t("form.address.step.error")}
								error={!!error}
							>
								<div>
									<Controller
										name={`steps.${index}.isLoading`}
										control={control}
										defaultValue={true}
										render={({field: {onChange: loadOnChage, value: loadValue}, fieldState: {error}}) => <>
											<CheckBox checked={loadValue} setChecked={(val) => {
												loadOnChage(val)
											}}/>
											<Text size="small" className="text-c-neutral">{loadValue ? "Chargement" : "Déchargement"}</Text>
										</>}
									/>
								</div>
								<div className={`flex w-full bg-white radius-common ${error ? "warningForm" : ""}`}>
									<Location
										type="primary"
										input={{
											value: addrValue.address || "" ,
											className: "m-2"
										}}
										placeholder={t("form.address.step.placeholder")}
										setAddress={addrOnChage}
									/>
								</div>
							</FormInput>}
						/>
					</div>)}
					<Controller
						name="unloadingAddress"
						control={control}
						defaultValue={undefined}
						rules={{required: true, validate: (value) => !!value?.address}}
						render={({field: {onChange, value}, fieldState: {error}}) => <FormInput className="pb-2"
							inputText={t("form.address.unloadingAddress.title")}
							titleSize="big"
							errorText={t("form.address.unloadingAddress.error")}
							error={!!error}
						>
							<Text size="small" className="text-c-neutral">{t("form.address.unloadingAddress.kind")}</Text>
							<div className={`flex bg-white radius-common ${error ? "warningForm" : ""}`}>
								<Location
									type="primary"
									input={{
										value: value && value.address ? value.address : "",
										className: "m-2 w-full"
									}}
									placeholder={t("form.address.unloadingAddress.placeholder")}
									setAddress={onChange}
								/>
							</div>
						</FormInput>}
					/>
				</div>
				{!locationClose && loadingAddress?.properties?.streetName && typology > 0 &&
					<div className="flex flex-col items-center w-4/6">
						<Controller
							name="slot"
							control={control}
							defaultValue={undefined}
							rules={{validate: (value) => (value?.id !== undefined && value?.id > 0) || getValues("instant")}}
							render={({field: {onChange}, fieldState: {error}}) => <div className="flex flex-col w-7/12 bg-c-warning">
								<Modal open={openSlotModal} showCloseIcon={true} onClose={() => setOpenSlotModal(false)} classNames={{modal: "radius-common"}}>
									<div className="flex flex-col">
										<div className="self-center mb-2">
											<Text size="big" className="text-c-neutral">{t("form.address.slot.picker.popupTitle")}</Text>
										</div>
										{slotsApi && slotsApi.slots && <SlotPicker
											data={slotsApi.slots}
											askChangeDate={(dateAsk) => changeSlotRange(dateAsk)}
											selectedSlot={{
												set: (slot) => {
													setOpenSlotModal(false)
													reservation.set((prevState) => ({
														...prevState,
														date: slot.resumeRange,
													}))
													onChange({
														id: slot.id,
														range: slot.fullStrRange
													})
												},
												value: getValues("slot.id")
											}}
										/>}
									</div>
								</Modal>
								<FormInput childClassName="flex w-full" titleSize="big" inputText={t("form.address.slot.title")} error={!!error} errorText={t("form.address.slot.error")}>
									<div
										className={`flex w-full bg-neutralLight radius-common ${error ? "warningForm" : ""}`}
										onClick={() => {
											if (!getValues("instant")) {
												setOpenSlotModal(true)
											}
										}}
									>
										<span className="w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal radius-common py-2">
											{selectedSlot ? selectedSlot : t("form.address.slot.placeholder")}
										</span>
										<Icon className="self-center mr-1" type="clock" size="medium"/>
									</div>
								</FormInput>
							</div>}
						/>
						<div className="flex items-center w-7/12 ml-5 mb-2">
							{slotsApi?.instantActive && <>
								<Text className="text-c-neutral pt-1" size="medium">
									{t("form.address.loadingAddress.instant")}
								</Text>
								<Controller
									name="instant"
									control={control}
									defaultValue={false}
									render={({field: {onChange, value}}) => <CheckBox setChecked={(val) => {
										setValue("slot.id", 0)
										reservation.set((prevState) => ({
											...prevState,
											slot: {
												id: 0,
												resume: val ? t("form.address.slot.instant", {date: format(new Date(), "eeee dd/MM", {locale: fr})}) : ""
											}
										}))
										setValue("slot.range", val ? t("form.address.slot.instant", {date: format(new Date(), "eeee dd/MM", {locale: fr})}) : "")
										onChange(val)
									}} checked={value}/>}
								/>
							</>}
							{!slotsApi?.instantActive && <>
								<Text className="text-c-neutral pt-1" size="medium">
									{t("form.address.loadingAddress.instant", { context: "disable" })}
              	</Text>
							</>}
						</div>
					</div>
				}
			</div>
			<div className="flex pb-5 w-2/6">
				{stepFields.length < maxStepField && <Button
					className="text-c-neutral"
					type="none"
					btnType="button"
					text={t("form.address.step.add")}
					onClick={() => appendStep({ addr: {}, isLoading: true })}
				/>}
			</div>
			<div className="flex flex-col w-full">
				{!apiError && locationClose && <Text className="self-center pb-2 text-c-warning">{t("form.address.error")}</Text>}
				{apiError && <Text className="self-center pb-2 text-c-warning">{t("form.address.error", { context: "api"})}</Text>}
				<div className="self-center w-2/6">
					<Button type="primary" btnType="submit" text={t("form.address.validation")}/>
				</div>
			</div>
		</form>
	</div>
}

export default AddressStep

